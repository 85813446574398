<template>
    <div class="db-container dashboard-market">
        <RobotSettingModal ref="robotSettingModal" :currencyList="this.type ? currencyListLoan : currencyListExchange" />
        <div class="db-modal mobile-filter">
            <div class="content">
                <div class="wrapper">
                    <h3>篩選標的</h3>
                    <div class="filters-container">
                        <span :class="higher ? 'selected' : ''" @click="higher = !higher">年報酬率高於中位數</span>
                        <span :class="deposit ? 'selected' : ''" @click="deposit = !deposit">可當保證金</span>
                        <div class="pair">
                            <h5>交易對</h5>
                            <span :class="pair == 0 ? 'selected' : ''" @click="pair = 0">全部</span>
                            <span :class="pair == 1 ? 'selected' : ''" @click="pair = 1">使用USDT</span>
                            <span :class="pair == 2 ? 'selected' : ''" @click="pair = 2">使用USD</span>
                        </div>
                    </div>
                </div>
                <div class="btn-container">
                    <button class="light" @click="closeModal()">應用</button>
                </div>
            </div>
        </div>
        <div class="db-content">
            <h2 class="title">
                <h2 v-if="false" :class="this.type == 0 ? 'active' : ''" @click="switchType(0)">FTX資金費率</h2>
                <h2 :class="this.type == 1 ? 'active' : ''" @click="switchType(1)">Bitfinex放貸</h2>
                <span>資料時間： {{ this.type ? (this.loanDataTime ? this.loanDataTime : '') : (this.exchangeDataTime ? this.exchangeDataTime : '') | recordDateFormat }}</span>
            </h2>
            <div class="filter-item">
                <div v-if="!this.type">
                    <h5>篩選</h5>
                    <span :class="higher ? 'selected' : ''" @click="higher = !higher">年報酬率高於中位數</span>
                    <span :class="deposit ? 'selected' : ''" @click="deposit = !deposit">可當保證金</span>
                </div>
                <div v-if="!this.type" class="pair">
                    <h5>交易對</h5>
                    <span :class="pair == 0 ? 'selected' : ''" @click="pair = 0">全部</span>
                    <span :class="pair == 1 ? 'selected' : ''" @click="pair = 1">使用USDT</span>
                    <span :class="pair == 2 ? 'selected' : ''" @click="pair = 2">使用USD</span>
                </div>
                <div v-if="!this.type" class="filter-mobile" @click="openModal()">
                    <div>
                        篩選 ({{ (pair != 0) + higher + deposit }})
                    </div>
                </div>
                <GlobalInput type="text" placeholder="搜尋標的" v-model="filterText" />
            </div>
            <div v-if="!this.type" class="table-container"> <!-- 資金費率 -->
                <table class="market-table">
                    <tr>
                        <th class="nohover">永續期貨標的</th>
                        <th @click="changeSorting(0)" :class="this.sorting.sortby == 0 ? (this.sorting.decending ? 'sorting' : 'sorting ace') : ''">即期費率</th>
                        <th @click="changeSorting(1)" :class="this.sorting.sortby == 1 ? (this.sorting.decending ? 'sorting' : 'sorting ace') : ''">預期費率</th>
                        <th @click="changeSorting(2)" :class="this.sorting.sortby == 2 ? (this.sorting.decending ? 'sorting' : 'sorting ace') : ''">年化報酬率</th>
                        <th @click="changeSorting(3)" :class="this.sorting.sortby == 3 ? (this.sorting.decending ? 'sorting' : 'sorting ace') : ''">連續期數</th>
                        <th class="nohover">可當保證金</th>
                        <th class="nohover">交易對</th>
                        <th class="nohover">建立機器人</th>
                    </tr>
                    <tr v-if="!exchangeLoaded" class="skeleton-loading-container">
                        <td><div class="skeleton-loading"></div></td>
                        <td><div class="skeleton-loading"></div></td>
                        <td><div class="skeleton-loading"></div></td>
                        <td><div class="skeleton-loading"></div></td>
                        <td><div class="skeleton-loading"></div></td>
                        <td><div class="skeleton-loading"></div></td>
                        <td><div class="skeleton-loading"></div></td>
                        <td><div class="skeleton-loading"></div></td>
                    </tr>
                    <tr v-for="(ele, ind) in orderListExchange" :key="ind">
                        <td>
                            <img :src="loadIcon(ele.future_name)" alt="" class="icon">
                            <h4 class="name">{{ ele.future_name }}</h4>
                        </td>
                        <td>{{ toPercent(ele.last_rate, 4) }}%</td>
                        <td>{{ toPercent(ele.next_rate, 4) }}%</td>
                        <td class="more">
                            {{ toPercent(ele.irr, 4) }}% <img src="@/assets/images/market-plus.svg" alt="">
                            <div class="more-rate">
                                <h5>近一個月年化</h5>
                                <p>{{ toPercent(ele.last_month_irr, 4) }}%</p>
                                <h5>連續年化</h5>
                                <p>{{ toPercent(ele.cum_irr, 4) }}%</p>
                            </div>
                        </td>
                        <td>{{ ele.cum_pos_rate - ele.cum_neg_rate }}</td>
                        <td>{{ ele.available_margin ? '是' : '否' }}</td>
                        <td><span v-if="ele.available_usdt">USDT</span><span class="usd" v-if="ele.available_usd">USD</span></td>
                        <td><button class="light" data-tip="您的FTX API設定尚未，請前往帳戶設定完成綁定！" :disabled="!(ftxApi ? ftxApi.status : false)" @click="$refs.robotSettingModal.openModal(0, 1, {'params': {'market': ele.future_name}}, [ele.available_usd, ele.available_usdt])">+ 建立</button></td>
                    </tr>
                    <!--
                    <tr>
                        <td>
                            <img src="https://s2.coinmarketcap.com/static/img/coins/200x200/5618.png" alt="" class="icon">
                            <h4 class="name">1INCH</h4>
                        </td>
                        <td>-0.0061%</td>
                        <td>-0.0054%</td>
                        <td class="more">
                            33.2830085% <img src="@/assets/images/market-plus.svg" alt="">
                            <div class="more-rate">
                                <h5>年化報酬率</h5>
                                <p>7.23830085%</p>
                                <h5>近一個月年化</h5>
                                <p>-2.23830085%</p>
                            </div>
                        </td>
                        <td>-351</td>
                        <td>是</td>
                        <td><span>USDT</span><span class="usd">USD</span></td>
                        <td><button class="light" @click="$refs.robotSettingModal.openModal(0, 1, 'mer')">+ 建立</button></td>
                    </tr>
                    -->
                </table>
            </div>
            <div v-if="this.type" class="table-container"> <!-- 放貸 -->
                <table class="market-table">
                    <tr>
                        <th class="nohover">幣別</th>
                        <th @click="changeSorting(0)" :class="this.sorting.sortby == 0 ? (this.sorting.decending ? 'sorting' : 'sorting ace') : ''">近一次成交利率</th>
                        <th @click="changeSorting(1)" :class="this.sorting.sortby == 1 ? (this.sorting.decending ? 'sorting' : 'sorting ace') : ''">近一次成交FRR年利率</th>
                        <th @click="changeSorting(2)" :class="this.sorting.sortby == 2 ? (this.sorting.decending ? 'sorting' : 'sorting ace') : ''">FRR</th>
                        <th @click="changeSorting(3)" :class="this.sorting.sortby == 3 ? (this.sorting.decending ? 'sorting' : 'sorting ace') : ''">年利率</th>
                        <th @click="changeSorting(4)" :class="this.sorting.sortby == 4 ? (this.sorting.decending ? 'sorting' : 'sorting ace') : ''">24H變化</th>
                        <th @click="changeSorting(5)" :class="this.sorting.sortby == 5 ? (this.sorting.decending ? 'sorting' : 'sorting ace') : ''">24H交易量</th>
                        <th class="nohover">建立機器人</th>
                    </tr>
                    <tr v-if="!loanLoaded" class="skeleton-loading-container">
                        <td><div class="skeleton-loading"></div></td>
                        <td><div class="skeleton-loading"></div></td>
                        <td><div class="skeleton-loading"></div></td>
                        <td><div class="skeleton-loading"></div></td>
                        <td><div class="skeleton-loading"></div></td>
                        <td><div class="skeleton-loading"></div></td>
                        <td><div class="skeleton-loading"></div></td>
                        <td><div class="skeleton-loading"></div></td>
                    </tr>
                    <tr v-for="(ele, ind) in orderListLoan" :key="ind">
                        <td>
                            <img :src="loadIcon(ele.currency)" alt="" class="icon">
                            <h4 class="name">{{ ele.currency }}</h4>
                        </td>
                        <td>{{ ele.last_rate.toFixed(4) }}%</td>
                        <td>{{ ele.last_rate_apr.toFixed(4) }}%</td>
                        <td>{{ ele.frr.toFixed(4) }}%</td>
                        <td>{{ ele.apr.toFixed(4) }}%</td>
                        <td>{{ ele.change.toFixed(4) }}%</td>
                        <td>{{ ele.volume_24h.toFixed(0) }} <span class="amount">USD</span></td>
                        <td><button class="light" data-tip="您的Bitfinex API設定尚未，請前往帳戶設定完成綁定！" :disabled="!(bitfinexApi ? bitfinexApi.status : false)" @click="$refs.robotSettingModal.openModal(0, 0, {'params': {'market': ele.currency}})">+ 建立</button></td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import $ from 'jquery';
import GlobalInput from '@/components/GlobalInput'
import RobotSettingModal from '@/components/RobotSettingModal';
import {apiFundingRate, apiLending} from '@/apis/market.js';
import {apiCheckKey} from '@/apis/account.js';
// import RobotsListsChart from '@/components/RobotsListsChart';
// import InputUnit from '@/components/InputUnit
// import {apiNewsDetail} from '@/apis/news.js';

export default {
    name: 'Market',
    beforeCreate: function() {
        document.body.className = 'noscroll';
    },
    data() {
        return {
            type: 1, // 0 for exchange, 1 for loan
            pair: 0, // 0 for all, 1 for usdt, 2 for usd
            higher: false,
            deposit: false,
            sorting: {
                sortby: 0,
                decending: true
            },
            marketListExchange: [],
            marketListLoan: [],
            marketMedian: 0,
            filterText: "",
            exchangeLoaded: false,
            loanLoaded: false,
            loanDataTime: null,
            exchangeDataTime: null,
            ftxApi: {},
            bitfinexApi: {}
        }
    },
    components: {
        GlobalInput,
        RobotSettingModal,
    },
    methods: {
        changeSorting(index, reset = false){
            if(this.sorting.sortby == index && !reset){
                this.sorting.decending = !this.sorting.decending
            }else{
                this.sorting.sortby = index
                this.sorting.decending = true
            }
        },
        resetTable(){
            this.changeSorting(0, true);
            this.pair = 0;
            this.higher = false;
            this.deposit = false;
            this.filterText = "";
        },
        switchType(to = 0){
            this.type = to
            this.resetTable()
            if(to && !this.loanLoaded)this.loadMarketLoan()
            if(!to && !this.exchangeLoaded)this.loadMarket()
        },
        openModal(){
            $('.mobile-filter').fadeIn(300);
        },
        closeModal(){
            $('.mobile-filter').fadeOut(300);
        },
        loadMarket(){ //資金費率
            apiFundingRate().then(res => {
                if(res.data.status){
                    this.marketListExchange = res.data.data;
                    this.exchangeLoaded = true;
                    let tmp = res.data.data;
                    tmp.sort((a, b) => (b.irr - a.irr))
                    var half = Math.floor(tmp.length / 2);
                    if (tmp.length % 2)this.marketMedian = tmp[half].irr;
                    else this.marketMedian = (tmp[half - 1].irr + tmp[half].irr) / 2.0;
                    this.exchangeDataTime = res.data.data.filter(ele => ele.future_name == 'BTC-PERP')[0].update_time;
                }
            })
        },
        loadMarketLoan(){ //借貸
            apiLending().then(res => {
                if(res.data.status){
                    this.marketListLoan = res.data.data;
                    let ustIndex = this.marketListLoan.findIndex(ele => ele.currency == 'UST')
                    if(ustIndex >= 0)this.marketListLoan[ustIndex].currency = 'USDT'
                    //console.log(res.data.data)
                    this.loanLoaded = true;
                    this.loanDataTime = res.data.data.filter(ele => ele.currency == 'BTC')[0].update_time;
                }
            })
        },
        toPercent(num = 0, limit = -1){
            return limit == -1 ? num*100 : (num*100).toFixed(limit)
        },
        loadIcon(name = ""){
            if(name == "GBP")return `https://static.bitfinex.com/images/icons/GBP-alt.svg`;
            try {
                return require(`@/assets/images/crypto-logos/${name.replace('-PERP', '')}.png`);
            } catch (e) {
                // return require(`@/assets/images/crypto-default.png`);
                return `https://static.bitfinex.com/images/icons/${name.replace('-PERP', '')}-alt.svg`;
            }
        },
        checkApiKey(){
            apiCheckKey({
                exchangeId: 'ftx'
            }).then(res => {
                this.ftxApi = res.data
            })
            apiCheckKey({
                exchangeId: 'bitfinex'
            }).then(res =>{
                this.bitfinexApi = res.data
            })
        },
    },
    mounted() {
        this.loadMarketLoan();
        this.checkApiKey();
        $('body').on('click', '.db-modal:not(.confirm-dialog):not(.robots-setting)', function(){
            $(this).fadeOut(300);
        })
        $('body').on('click', '.close-modal', function(){
            $('.api-setting-modal').fadeOut(300);
        })
        $('body').on('click', '.db-modal > .content', function(e){
            e.stopPropagation();
        })
    },
    computed: {
        orderListExchange: function(){
            var _this = this;
            var tmp = _this.marketListExchange;
            switch(this.sorting.sortby){
                case 0:
                    tmp.sort((a, b) => (b.last_rate - a.last_rate) * (this.sorting.decending ? 1 : -1))
                    break;
                case 1:
                    tmp.sort((a, b) => (b.next_rate - a.next_rate) * (this.sorting.decending ? 1 : -1))
                    break;
                case 2:
                    tmp.sort((a, b) => (b.irr - a.irr) * (this.sorting.decending ? 1 : -1))
                    break;
                case 3:
                    tmp.sort((a, b) => ((b.cum_pos_rate - b.cum_neg_rate) - (a.cum_pos_rate - a.cum_neg_rate)) * (this.sorting.decending ? 1 : -1))
                    break;
                default:
                    tmp.sort((a, b) => (b.last_rate - a.last_rate) * (this.sorting.decending ? 1 : -1))
            }
            return tmp.filter(ele => {
                if(!ele.available_usdt && !ele.available_usd)return false
                if(this.deposit){
                    if(!ele.available_margin)return false
                }
                if(this.higher){
                    if(ele.irr < this.marketMedian)return false
                }
                if(this.pair==1){
                    if(!ele.available_usdt)return false
                }
                if(this.pair==2){
                    if(!ele.available_usd)return false
                }
                return ele.future_name.toLowerCase().replace('-perp','').indexOf(this.filterText.toLowerCase())>=0
            });
        },
        orderListLoan: function(){
            var _this = this;
            var tmp = _this.marketListLoan;
            switch(this.sorting.sortby){
                case 0:
                    tmp.sort((a, b) => (b.last_rate - a.last_rate) * (this.sorting.decending ? 1 : -1))
                    break;
                case 1:
                    tmp.sort((a, b) => (b.last_rate_apr - a.last_rate_apr) * (this.sorting.decending ? 1 : -1))
                    break;
                case 2:
                    tmp.sort((a, b) => (b.frr - a.frr) * (this.sorting.decending ? 1 : -1))
                    break;
                case 3:
                    tmp.sort((a, b) => (b.apr - a.apr) * (this.sorting.decending ? 1 : -1))
                    break;
                case 4:
                    tmp.sort((a, b) => (b.change - a.change) * (this.sorting.decending ? 1 : -1))
                    break;
                case 5:
                    tmp.sort((a, b) => (b.volume_24h - a.volume_24h) * (this.sorting.decending ? 1 : -1))
                    break;
                default:
                    tmp.sort((a, b) => (b.apr - a.apr) * (this.sorting.decending ? 1 : -1))
            }
            return tmp.filter(ele => {
                return ele.currency.toLowerCase().indexOf(this.filterText.toLowerCase())>=0
            });
        },
        currencyListExchange: function(){
            return this.marketListExchange.map(ele => {
                return {
                    title: ele.future_name,
                    value: ele.future_name
                }
            })
        },
        currencyListLoan: function(){
            return this.marketListLoan.map(ele => {
                return {
                    title: ele.currency,
                    value: ele.currency
                }
            })
        }
    },
}
</script>